.text-link {
  cursor: pointer;
  color: #5469d4;
  text-decoration: unset;
  display: block;
  text-align: center;
  font-weight: 600;
}

.page-root{
  padding: 160px 0 0;

  .page-wrapper {
    margin: 32px;
  }
}

.confirmation-txt {
  text-align: center;
  padding: 12px 0;
}

.predictions-stats-block {
  p {

  }
  span {
    font-weight: 600;
    color: rgb(75, 98, 213);
    cursor: pointer;
  }
}

.result-text {
  margin: 8px 0 0;

  span {
    font-weight: 600;

    &.win {
      color: #008000;
    }

    &.less-win {
      color: #8daf52;
    }

    &.lost {
      color: #f92626;
    }
  }
}

.position-relative {
  position: relative;
}

.table-container {
  margin: 8px 0;

  .table-head-block {
    border-bottom: 1px solid black;
    padding: 0 0 8px;
    margin: 0 0 8px;

    .row-item {
      font-weight: 600;
    }
  }

  .table-block {
    max-height: 80vh;
    overflow: auto;
  }

  .table-item {
    display: flex;
    border-top: 1px solid #00000050;
    padding: 4px 0;

    &:first-child {
      border: none;
    }
  }

  .row-item {
    width: 50px;

    &.no {
      width: 30px;
    }

    &.player {
      width: 180px;
    }

    &.prediction {
      width: 160px;
    }

    &.points {
      width: 120px;
    }
  }

  .table-block {
    .row-item {
      &.points {
        text-align: center;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .page-root {
    padding: 80px 0 0;

    .page-wrapper {
      margin: 32px 18px;
    }
  }
}

