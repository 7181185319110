.lb-root {
    .lb-wrapper {
        cursor: default;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;

        &.bg-shadow {
            background-color: #00000050;
        }
    }

    .lb-container {
        box-shadow: rgba(60, 66, 87, 0.12) 0 7px 14px 0, rgba(0, 0, 0, 0.12) 0 3px 6px 0;
        border-radius: 12px;
        padding: 16px;
        background-color: #FFFFFF;
        max-width: 400px;

        &.statistics {
            min-width: 300px;

            .lb-head-block {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0 0 4px;

                span {
                    color: black;
                    font-size: 12px;
                    font-weight: 700;
                    cursor: default;
                }
            }

            .stats-block {
                font-size: 14px;
                font-weight: 400;

                span {
                    color: #00000090;
                    font-weight: 700;
                    cursor: default;
                }
            }

            .stat-item {
                border-top: 1px solid #00000050;
                padding: 4px 0;
                margin: 4px 0;
            }
        }
    }
}