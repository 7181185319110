
.season-page-root {
  @extend .page-root;

  .season-page-wrapper {
    @extend .page-wrapper;
  }

  .title-block {
    margin: 0 0 40px;
    h1 {
      text-align: center;
    }
  }

  .rules-container {
    margin: 40px 0 0;

    .toggle-item {
      cursor: pointer;
      display: flex;
      align-items: center;
      width: fit-content;

      span {
        font-size: 22px;
        font-weight: 700;
      }
    }

    p {
      margin: 8px 0;
    }
  }
}

.season-panel-page-root {
  @extend .page-root;

  .season-panel-page-wrapper {
    @extend .page-wrapper;
  }

  .applicant-item {
    display: flex;
    margin: 18px 0;

    span{
      font-weight: 600;
    }

    button {
      margin: 10px;
    }
  }

  .players-container {
    display: flex;
    flex-wrap: wrap;
  }

  .player-item {
    padding: 0 32px 8px 0;

    span{
      font-weight: 600;
    }

    .input-item {
      display: inline-flex;
      margin: 0 4px;
      input {
        font-size: 14px;
        line-height: 16px;
        padding: 4px 8px;
        min-height: 16px;
        width: 60px;
      }
    }

    .btn-item {
      font-weight: 500;
      display: inline-flex;
      border-radius: 10px;
      padding: 3px 6px;
      font-size: 10px;
    }
  }
}

.predictions-root {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    margin: 12px 0;
  }

  h3 {
    margin: 0 0 12px 0;
  }

  .player-points-container {
    display: none;
    position: absolute;
    top: 0;
    left: 0;

    box-shadow: rgba(60, 66, 87, 0.12) 0 7px 14px 0, rgba(0, 0, 0, 0.12) 0 3px 6px 0;
    border-radius: 12px;
    padding: 16px;
    background-color: #FFFFFF;
  }

  .player:hover {
    position: relative;
    .player-points-container {
      display: block;
    }
  }
}