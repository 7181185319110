
.login-page-root {
  height: 100vh;

  .login-page-wrapper {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}