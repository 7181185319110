
.header-root  {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: background .5s;
  background-color: rgba(24, 23, 23, .7);
  z-index: 100;

  &:hover {
    background-color: rgba(0, 0, 0, .7);

    .open-profile-menu {
      .nav-item {
        background-color: rgba(0, 0, 0, .7);
      }
    }
  }

  .header-wrapper {
    position: relative;
  }

  .header-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 32px 0 32px;
  }

  .header-block {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.left {
      justify-content: flex-end;
    }

    &.right {

    }
  }

  .nav-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px 18px;
    cursor: pointer;

    span {
      color: white;
      font-weight: 500;
      font-size: 18px;
    }

    &:hover {
      span {
        color: #ed1c24;
      }

      .chevron-icon {
        border-bottom-color: #ed1c24;
        border-right-color: #ed1c24;
      }
    }
  }

  .header-img-item {
    height: 130px;
    width: 130px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 20px -72px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .profile-block {

  }

  .open-profile-menu {
    position: absolute;
    top: 80px;
    right: -1px;

    .nav-item {
      background-color: #181717;
      padding: 25px 43px;
      transition: background .5s;
      width: 160px;

      span {
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: flex-end;

        svg {
          margin: 0 8px 0 0;
        }
      }

      &:hover {

        .profile {
          svg{
            fill: #ed1c24;
          }
        }

        .admin {
          svg{
            fill: #ed1c24;
          }
        }

        .log-out {
          svg path{
            fill: #ed1c24;
          }
        }


      }

    }
  }

}

@media only screen and (max-width: 768px) {

  .header-root {
    background-color: rgba(0, 0, 0, .7);

    .header-container {
      padding: 0 32px;
    }

    .nav-item {
      span {
        font-size: 16px;
      }
    }

    .header-img-item {
      margin: 0;
      width: 64px;
      height: 64px;
    }

    .open-profile-menu {
      top: 71px;

      .nav-item {
        padding: 20px 45px;
        width: 140px;
      }
    }
  }

}

@media only screen and (max-width: 600px) {

  .header-root {

    .header-container {
      padding: 0 18px;
    }

    .header-block {
      &.left {
        flex: none;
      }
    }

    .header-img-item {
      order: -1;
    }
  }
}

@media only screen and (max-width: 370px) {

  .header-root {

    .header-container {
      padding: 0 8px;
    }

    .header-block {
      &.left {
        flex: none;
      }
    }

    .header-img-item {
      order: -1;
    }

    .nav-item {
      padding: 25px 8px;
    }
  }
}