

.form-container {
  padding: 24px;
  box-shadow: rgba(60, 66, 87, 0.12) 0 7px 14px 0, rgba(0, 0, 0, 0.12) 0 3px 6px 0;
  border-radius: 4px;
  background-color: #FFFFFF;
  max-width: 370px;
  width: 100%;

  h2 {
    font-size: 20px;
    line-height: 28px;
    color: #1a1f36;
    padding-bottom: 18px;
    font-weight: normal;
    text-align: center;
  }

  .PhoneInput {
    input {
      padding: 0 4px;
      min-height: 36px;
      &::placeholder {
        font-size: 12px;
      }
    }
  }

  &.predictions {
    max-width: 390px;

    .input-item {
      border-top: 1px solid #00000050;
      padding-top: 16px;
    }
  }
}

.input-item {
  padding: 0 0 18px;
  position: relative;

  input {
    font-size: 16px;
    line-height: 28px;
    padding: 8px 16px;
    width: 100%;
    min-height: 44px;
    border: 1px solid rgba(199, 192, 192, 0.5);
    border-radius: 4px;
    outline-color: rgb(84 105 212 / 0.5);
    background-color: rgb(255, 255, 255);
  }

  label {
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 10px;
  }
}

.input-error-text {
  margin: 8px 0;
  display: block;
  text-align: center;
  font-weight: 700;
  color: #e54d4d;
  font-size: 10px;
}