.btn-item {
  display: flex;
  cursor: pointer;
  color: #000000;
  background-color: #3264d0;
  border-radius: 12px;
  padding: 6px 12px;
  border: none;
  @include font-style(14px, #FFF);
  margin: 0 auto;

  &:hover {
    background-color: #3660bd;
  }

  &.disabled {
    pointer-events: none;
    background-color: #8a8a8a;
    box-shadow: none;
  }
}

.submit-btn {
  @extend .btn-item;
  background-color: rgb(84, 105, 212);
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0.12) 0 1px 1px 0, rgb(84, 105, 212) 0 0 0 1px, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(60, 66, 87, 0.08) 0 2px 5px 0;
  color: #fff;
  font-weight: 600;

  &:hover {
    background-color: #687ee7;
  }
}

.select-btn-block {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 24px;
  justify-content: center;

  .select-btn-item {
    margin: 4px 8px;
  }
}



.white-btn {
  cursor: pointer;

  background-color: #fff;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #0d172a;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  text-align: center;
  transition: all .1s cubic-bezier(.4, 0, .2, 1);
  box-shadow: 0 4px 8px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:hover {
    background-color: #1e293b;
    color: #fff;
  }

  &.active {
    background-color: #1e293b;
    color: #fff;
  }
}

