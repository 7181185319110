* {
  box-sizing: border-box;
}

i {
  line-height: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  background-color: #f4f4f4;
}

body {
  margin: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  background-color: transparent;
}
a:focus {
  outline: 0;
}

a:active,
a:hover,
a:focus {
  outline-width: 0;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
}

button,
select {
  /* 1 */
  text-transform: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
button:focus,
button:hover [type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border: none;
  outline: none;
}

button:-moz-focusring,
[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring {
  outline: none;
}

textarea {
  overflow: auto;
}

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

div,
span,
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

html {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Ubuntu, sans-serif;
}

.chevron-icon {
  width: 8px;
  height: 8px;
  min-width: 8px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(45deg);
  transition: transform 0.2s ease-in-out;
  margin: 0 0 5px 10px;
}
.chevron-icon.open {
  transform: rotate(225deg);
  margin: 4px 0 0 10px;
}
.chevron-icon.black {
  border-bottom: 2px solid black;
  border-right: 2px solid black;
}

.close-icon {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin: 0 0 0 auto;
}
.close-icon::after {
  content: "";
  width: 24px;
  height: 2px;
  display: block;
  position: absolute;
  background-color: black;
  transform: rotate(45deg);
}
.close-icon::before {
  content: "";
  width: 24px;
  height: 2px;
  display: block;
  position: absolute;
  transform: rotate(-45deg);
  background-color: black;
}

.btn-item, .submit-btn {
  display: flex;
  cursor: pointer;
  color: #000000;
  background-color: #3264d0;
  border-radius: 12px;
  padding: 6px 12px;
  border: none;
  font-size: 14px;
  color: #FFF;
  margin: 0 auto;
}
.btn-item:hover, .submit-btn:hover {
  background-color: #3660bd;
}
.btn-item.disabled, .disabled.submit-btn {
  pointer-events: none;
  background-color: #8a8a8a;
  box-shadow: none;
}

.submit-btn {
  background-color: rgb(84, 105, 212);
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0.12) 0 1px 1px 0, rgb(84, 105, 212) 0 0 0 1px, rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0, rgba(60, 66, 87, 0.08) 0 2px 5px 0;
  color: #fff;
  font-weight: 600;
}
.submit-btn:hover {
  background-color: #687ee7;
}

.select-btn-block {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 24px;
  justify-content: center;
}
.select-btn-block .select-btn-item {
  margin: 4px 8px;
}

.white-btn {
  cursor: pointer;
  background-color: #fff;
  border-radius: 1.5rem;
  box-sizing: border-box;
  color: #0d172a;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  text-align: center;
  transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 8px rgba(166, 175, 195, 0.25);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.white-btn:hover {
  background-color: #1e293b;
  color: #fff;
}
.white-btn.active {
  background-color: #1e293b;
  color: #fff;
}

.form-container {
  padding: 24px;
  box-shadow: rgba(60, 66, 87, 0.12) 0 7px 14px 0, rgba(0, 0, 0, 0.12) 0 3px 6px 0;
  border-radius: 4px;
  background-color: #FFFFFF;
  max-width: 370px;
  width: 100%;
}
.form-container h2 {
  font-size: 20px;
  line-height: 28px;
  color: #1a1f36;
  padding-bottom: 18px;
  font-weight: normal;
  text-align: center;
}
.form-container .PhoneInput input {
  padding: 0 4px;
  min-height: 36px;
}
.form-container .PhoneInput input::placeholder {
  font-size: 12px;
}
.form-container.predictions {
  max-width: 390px;
}
.form-container.predictions .input-item {
  border-top: 1px solid rgba(0, 0, 0, 0.3137254902);
  padding-top: 16px;
}

.input-item {
  padding: 0 0 18px;
  position: relative;
}
.input-item input {
  font-size: 16px;
  line-height: 28px;
  padding: 8px 16px;
  width: 100%;
  min-height: 44px;
  border: 1px solid rgba(199, 192, 192, 0.5);
  border-radius: 4px;
  outline-color: rgba(84, 105, 212, 0.5);
  background-color: rgb(255, 255, 255);
}
.input-item label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 10px;
}

.input-error-text {
  margin: 8px 0;
  display: block;
  text-align: center;
  font-weight: 700;
  color: #e54d4d;
  font-size: 10px;
}

.text-link {
  cursor: pointer;
  color: #5469d4;
  text-decoration: unset;
  display: block;
  text-align: center;
  font-weight: 600;
}

.page-root, .season-panel-page-root, .season-page-root {
  padding: 160px 0 0;
}
.page-root .page-wrapper, .season-panel-page-root .page-wrapper, .season-panel-page-root .season-panel-page-wrapper, .season-page-root .page-wrapper, .season-page-root .season-page-wrapper {
  margin: 32px;
}

.confirmation-txt {
  text-align: center;
  padding: 12px 0;
}

.predictions-stats-block span {
  font-weight: 600;
  color: rgb(75, 98, 213);
  cursor: pointer;
}

.result-text {
  margin: 8px 0 0;
}
.result-text span {
  font-weight: 600;
}
.result-text span.win {
  color: #008000;
}
.result-text span.less-win {
  color: #8daf52;
}
.result-text span.lost {
  color: #f92626;
}

.position-relative {
  position: relative;
}

.table-container {
  margin: 8px 0;
}
.table-container .table-head-block {
  border-bottom: 1px solid black;
  padding: 0 0 8px;
  margin: 0 0 8px;
}
.table-container .table-head-block .row-item {
  font-weight: 600;
}
.table-container .table-block {
  max-height: 80vh;
  overflow: auto;
}
.table-container .table-item {
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.3137254902);
  padding: 4px 0;
}
.table-container .table-item:first-child {
  border: none;
}
.table-container .row-item {
  width: 50px;
}
.table-container .row-item.no {
  width: 30px;
}
.table-container .row-item.player {
  width: 180px;
}
.table-container .row-item.prediction {
  width: 160px;
}
.table-container .row-item.points {
  width: 120px;
}
.table-container .table-block .row-item.points {
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .page-root, .season-panel-page-root, .season-page-root {
    padding: 80px 0 0;
  }
  .page-root .page-wrapper, .season-panel-page-root .page-wrapper, .season-panel-page-root .season-panel-page-wrapper, .season-page-root .page-wrapper, .season-page-root .season-page-wrapper {
    margin: 32px 18px;
  }
}
.lb-root .lb-wrapper {
  cursor: default;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.lb-root .lb-wrapper.bg-shadow {
  background-color: rgba(0, 0, 0, 0.3137254902);
}
.lb-root .lb-container {
  box-shadow: rgba(60, 66, 87, 0.12) 0 7px 14px 0, rgba(0, 0, 0, 0.12) 0 3px 6px 0;
  border-radius: 12px;
  padding: 16px;
  background-color: #FFFFFF;
  max-width: 400px;
}
.lb-root .lb-container.statistics {
  min-width: 300px;
}
.lb-root .lb-container.statistics .lb-head-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 4px;
}
.lb-root .lb-container.statistics .lb-head-block span {
  color: black;
  font-size: 12px;
  font-weight: 700;
  cursor: default;
}
.lb-root .lb-container.statistics .stats-block {
  font-size: 14px;
  font-weight: 400;
}
.lb-root .lb-container.statistics .stats-block span {
  color: rgba(0, 0, 0, 0.5647058824);
  font-weight: 700;
  cursor: default;
}
.lb-root .lb-container.statistics .stat-item {
  border-top: 1px solid rgba(0, 0, 0, 0.3137254902);
  padding: 4px 0;
  margin: 4px 0;
}

.loader .lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loader .lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.loader .lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #1f64bd;
  margin: -4px 0 0 -4px;
}
.loader .lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.loader .lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.loader .lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.loader .lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.loader .lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.loader .lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.loader .lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.loader .lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.loader .lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.loader .lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.loader .lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.loader .lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.loader .lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.loader .lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.loader .lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.loader .lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.header-root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transition: background 0.5s;
  background-color: rgba(24, 23, 23, 0.7);
  z-index: 100;
}
.header-root:hover {
  background-color: rgba(0, 0, 0, 0.7);
}
.header-root:hover .open-profile-menu .nav-item {
  background-color: rgba(0, 0, 0, 0.7);
}
.header-root .header-wrapper {
  position: relative;
}
.header-root .header-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 32px 0 32px;
}
.header-root .header-block {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header-root .header-block.left {
  justify-content: flex-end;
}
.header-root .nav-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px 18px;
  cursor: pointer;
}
.header-root .nav-item span {
  color: white;
  font-weight: 500;
  font-size: 18px;
}
.header-root .nav-item:hover span {
  color: #ed1c24;
}
.header-root .nav-item:hover .chevron-icon {
  border-bottom-color: #ed1c24;
  border-right-color: #ed1c24;
}
.header-root .header-img-item {
  height: 130px;
  width: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 20px -72px;
  cursor: pointer;
}
.header-root .header-img-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.header-root .open-profile-menu {
  position: absolute;
  top: 80px;
  right: -1px;
}
.header-root .open-profile-menu .nav-item {
  background-color: #181717;
  padding: 25px 43px;
  transition: background 0.5s;
  width: 160px;
}
.header-root .open-profile-menu .nav-item span {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
}
.header-root .open-profile-menu .nav-item span svg {
  margin: 0 8px 0 0;
}
.header-root .open-profile-menu .nav-item:hover .profile svg {
  fill: #ed1c24;
}
.header-root .open-profile-menu .nav-item:hover .admin svg {
  fill: #ed1c24;
}
.header-root .open-profile-menu .nav-item:hover .log-out svg path {
  fill: #ed1c24;
}

@media only screen and (max-width: 768px) {
  .header-root {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .header-root .header-container {
    padding: 0 32px;
  }
  .header-root .nav-item span {
    font-size: 16px;
  }
  .header-root .header-img-item {
    margin: 0;
    width: 64px;
    height: 64px;
  }
  .header-root .open-profile-menu {
    top: 71px;
  }
  .header-root .open-profile-menu .nav-item {
    padding: 20px 45px;
    width: 140px;
  }
}
@media only screen and (max-width: 600px) {
  .header-root .header-container {
    padding: 0 18px;
  }
  .header-root .header-block.left {
    flex: none;
  }
  .header-root .header-img-item {
    order: -1;
  }
}
@media only screen and (max-width: 370px) {
  .header-root .header-container {
    padding: 0 8px;
  }
  .header-root .header-block.left {
    flex: none;
  }
  .header-root .header-img-item {
    order: -1;
  }
  .header-root .nav-item {
    padding: 25px 8px;
  }
}
.login-page-root {
  height: 100vh;
}
.login-page-root .login-page-wrapper {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.season-page-root .title-block {
  margin: 0 0 40px;
}
.season-page-root .title-block h1 {
  text-align: center;
}
.season-page-root .rules-container {
  margin: 40px 0 0;
}
.season-page-root .rules-container .toggle-item {
  cursor: pointer;
  display: flex;
  align-items: center;
  width: fit-content;
}
.season-page-root .rules-container .toggle-item span {
  font-size: 22px;
  font-weight: 700;
}
.season-page-root .rules-container p {
  margin: 8px 0;
}

.season-panel-page-root .applicant-item {
  display: flex;
  margin: 18px 0;
}
.season-panel-page-root .applicant-item span {
  font-weight: 600;
}
.season-panel-page-root .applicant-item button {
  margin: 10px;
}
.season-panel-page-root .players-container {
  display: flex;
  flex-wrap: wrap;
}
.season-panel-page-root .player-item {
  padding: 0 32px 8px 0;
}
.season-panel-page-root .player-item span {
  font-weight: 600;
}
.season-panel-page-root .player-item .input-item {
  display: inline-flex;
  margin: 0 4px;
}
.season-panel-page-root .player-item .input-item input {
  font-size: 14px;
  line-height: 16px;
  padding: 4px 8px;
  min-height: 16px;
  width: 60px;
}
.season-panel-page-root .player-item .btn-item, .season-panel-page-root .player-item .submit-btn {
  font-weight: 500;
  display: inline-flex;
  border-radius: 10px;
  padding: 3px 6px;
  font-size: 10px;
}

.predictions-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.predictions-root h2 {
  margin: 12px 0;
}
.predictions-root h3 {
  margin: 0 0 12px 0;
}
.predictions-root .player-points-container {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: rgba(60, 66, 87, 0.12) 0 7px 14px 0, rgba(0, 0, 0, 0.12) 0 3px 6px 0;
  border-radius: 12px;
  padding: 16px;
  background-color: #FFFFFF;
}
.predictions-root .player:hover {
  position: relative;
}
.predictions-root .player:hover .player-points-container {
  display: block;
}

