
.chevron-icon {
  width: 8px;
  height: 8px;
  min-width: 8px;
  border-bottom: 2px solid white;
  border-right: 2px solid white;
  transform: rotate(45deg);
  transition: transform .2s ease-in-out;
  margin: 0 0 5px 10px;

  &.open{
    transform: rotate(225deg);
    margin: 4px 0 0 10px;
  }

  &.black {
    border-bottom: 2px solid black;
    border-right: 2px solid black;
  }
}

.close-icon {
  @include close-icon(24px, black);
  margin: 0 0 0 auto;
}